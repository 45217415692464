import {
  Form,
  Link,
  NavLink,
  useFetcher,
  useLoaderData,
} from '@remix-run/react';
import { type UseGetWebNavBarItems, useGetWebNavBarItems } from './nav-bar.tsx';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { PersonIcon, ChevronDownIcon } from '@radix-ui/react-icons';
import logger from '~/services/logger';
import { useState } from 'react';
import type { loader as rootLoader } from '~/routes/_index.ts';
import { useIsLoggedIn } from '~/hooks/use-is-logged-in.ts';

export interface NavbarAccountDropdown {
  id?: string;
  type?: string;
  title?: string;
  general?: {
    settings_screen_title?: string;
    login_screen_title?: string;
    signin_text_label?: string;
    signout_text_label?: string;
    signup_button_switch?: boolean;
    signup_text_label?: string;
    signup_link?: string;
    text_label_1?: string;
    link_1?: string;
    text_label_2?: string;
    link_2?: string;
    text_label_3?: string;
    link_3?: string;
    text_label_4?: string;
    link_4?: string;
    text_label_5?: string;
    link_5?: string;
  };
  userAccountScreenId?: string;
}

export function UserAccountDropdown(): JSX.Element {
  const { debugLayoutId } = useLoaderData<typeof rootLoader>();

  const fetcher = useFetcher();

  const [initSignOut, setInitSignOut] = useState<boolean>(false);

  const { navbarAccountDropdown, navbarError, navBarId }: UseGetWebNavBarItems =
    useGetWebNavBarItems();

  const { isLoggedIn, preventRenderByIsLoggedIn } = useIsLoggedIn();

  if (navbarError || !navbarAccountDropdown || preventRenderByIsLoggedIn)
    return <></>;

  const settingsScreenTitle: string =
    navbarAccountDropdown?.general?.settings_screen_title || 'My Account';

  const userAccountScreenId: string | undefined =
    navbarAccountDropdown?.userAccountScreenId;

  const dropdownMenuItemClassName: string =
    'group relative flex h-[30px] select-none items-center rounded-[3px] px-[5px] pl-[25px] text-[13px] leading-none text-white outline-none data-[disabled]:pointer-events-none hover:bg-[rgba(255,255,255,.15)]';

  try {
    const onLogoutClick = (e: any) => {
      setInitSignOut(true);
      fetcher.submit(e.target.form);
    };

    const signinLabel = navbarAccountDropdown?.general?.signin_text_label;
    const signoutLabel = navbarAccountDropdown?.general?.signout_text_label;

    const signupSwitch = navbarAccountDropdown?.general?.signup_button_switch;
    const signupLabel =
      navbarAccountDropdown?.general?.signup_text_label || 'SIGN UP';
    const signupLink = navbarAccountDropdown?.general?.signup_link;

    if (!isLoggedIn && !initSignOut) {
      return (
        <Form method="get" action="/login">
          <button
            className="mr-5 hover:text-navbar-font-color-hover data-[disabled]:pointer-events-none"
            type="submit"
            aria-label={`${signinLabel}-button`}
          >
            {signinLabel}
          </button>

          {debugLayoutId && (
            <input type="hidden" name="layout-id" value={debugLayoutId} />
          )}

          {signupSwitch && !!signupLink && (
            <SignUpButton signupLabel={signupLabel} signupLink={signupLink} />
          )}
        </Form>
      );
    }

    const {
      text_label_1,
      text_label_2,
      text_label_3,
      text_label_4,
      text_label_5,
    } = navbarAccountDropdown?.general || {};

    const { link_1, link_2, link_3, link_4, link_5 } =
      navbarAccountDropdown?.general || {};

    return (
      <>
        <div role="menubar" className="flex flex-col gap-1 lg:hidden">
          {text_label_1 && link_1 && (
            <NavLink aria-label="menubar item" to={link_1}>
              {text_label_1}
            </NavLink>
          )}
          {text_label_2 && link_2 && (
            <NavLink aria-label="menubar item" to={link_2}>
              {text_label_2}
            </NavLink>
          )}
          {text_label_3 && link_3 && (
            <NavLink aria-label="menubar item" to={link_3}>
              {text_label_3}
            </NavLink>
          )}
          {text_label_4 && link_4 && (
            <NavLink aria-label="menubar item" to={link_4}>
              {text_label_4}
            </NavLink>
          )}
          {text_label_5 && link_5 && (
            <NavLink aria-label="menubar item" to={link_5}>
              {text_label_5}
            </NavLink>
          )}
          <Form method="post" action="/api/logout">
            <button
              type="submit"
              aria-label={`Click to sign out`}
              onClick={onLogoutClick}
            >
              {signoutLabel}
            </button>
          </Form>
        </div>

        <div className="hidden lg:flex" data-testid="account-dropdown-button">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <button
                className="inline-flex items-center justify-center outline-none sm:mr-5"
                aria-label="Dropdown"
                key="person-icon-button-key"
              >
                <PersonIcon height={25} width={25} />
                <ChevronDownIcon height={20} width={20} />
              </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenu.Content
                align="end"
                className={`navigation-${navBarId} z-[200] min-w-[220px] rounded-md border-[1px] border-[#525a5d80] bg-navbar-background-color p-[5px]`}
                sideOffset={5}
              >
                {userAccountScreenId && (
                  <DropDownItem
                    label={settingsScreenTitle}
                    link={userAccountScreenId}
                  />
                )}

                <DropDownItem label={text_label_1} link={link_1} />
                <DropDownItem label={text_label_2} link={link_2} />
                <DropDownItem label={text_label_3} link={link_3} />
                <DropDownItem label={text_label_4} link={link_4} />
                <DropDownItem label={text_label_5} link={link_5} />

                <Form method="post" action="/api/logout">
                  <DropdownMenu.Item
                    className={dropdownMenuItemClassName}
                    asChild
                  >
                    <button
                      className="w-full"
                      type="submit"
                      aria-label={`${signoutLabel}-button`}
                      onClick={onLogoutClick}
                    >
                      {signoutLabel}
                    </button>
                  </DropdownMenu.Item>
                </Form>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        </div>
      </>
    );

    function DropDownItem({ label, link }: { label?: string; link?: string }) {
      if (!label || !link) return <></>;

      return (
        <>
          <DropdownMenu.Item className={dropdownMenuItemClassName} asChild>
            <Link to={`/redirect-to?url=${encodeURIComponent(link)}`}>
              {label}
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="m-[5px] h-[1px] bg-[#525a5d80]" />
        </>
      );
    }
  } catch (error: any) {
    logger.info(`UserAccountDropdown: ${error.message}`);
    return <></>;
  }
}

function SignUpButton({
  signupLabel,
  signupLink,
}: {
  signupLabel: string;
  signupLink: string;
}): JSX.Element {
  try {
    return (
      <button
        className="focus:shadow-outline-blue rounded-signup-button-radius border-none bg-signup-button-background-color pb-signup-button-b pl-signup-button-l pr-signup-button-r pt-signup-button-t text-signup-button-text-font-size text-signup-button-text-color hover:bg-signup-button-background-color-hover focus:outline-none active:bg-signup-button-background-color-hover"
        type="button"
        tabIndex={-1}
      >
        <Link
          aria-label={`${signupLabel}-button`}
          to={signupLink}
          target="_blank"
        >
          {signupLabel}
        </Link>
      </button>
    );
  } catch (error: any) {
    logger.info(`SignUpButton: ${error.message}`);
    return <></>;
  }
}
